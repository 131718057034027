import React from 'react';
import { CmsContentRenderer } from '@features/cms/components/cms-content-renderer';
import { Container } from '@goodfynd/react-web.layout.container';
import Head from 'next/head';

import { setPageTitle } from '../utils/base';

export default function Custom404() {
  return (
    <>
      <Head>
        <title>{setPageTitle('Oops 😭')}</title>
        <meta content="noindex, nofollow" name="robots" />
      </Head>

      <Container
        css={{
          '&.MuiContainer-root': {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: 32,
          },
        }}
      >
        <CmsContentRenderer slug="vendor-404" />
      </Container>
    </>
  );
}
